import React, { useEffect } from 'react'
import Image from 'gatsby-plugin-sanity-image'
import { Link, graphql } from 'gatsby'

import Layout from '../components/layout/layout'
import SEO from '../components/seo'
import BlockContent from '../components/block-content'

import ArrowCTA from '../assets/svg/arrow_cta.svg'

const StoryPage = ({ data, pageContext }) => {
  const
    { _rawBody, description, image, title  } = data.sanityStory,
    { next } = pageContext;

    useEffect(() => {
      const links = document.querySelectorAll(".block-container a");

      for (let i = 0; i < links.length; i++) {
          links[i].setAttribute("target", "__blank");
          links[i].classList.add('action-gray');
      }
    })

  return(
    <Layout>
      <SEO title={title} />
      <section id="story-container-headings">
        <div className="image-container" data-sal="fade" data-sal-duration="650">
          <Image {...image} width={800}/>
        </div>
        <div className="heading-container">
          <h1 data-sal="slide-up" data-sal-delay="100" data-sal-duration="850">{title}</h1>
          <p className="block-small" data-sal="fade" data-sal-duration="650">{description}</p>
        </div>
      </section>
      <section id="story-container-body" className="grid">
        <BlockContent blocks={_rawBody || []} className="block-container"/>
        <div className="controls">
          <Link to="/stories" className="back action"><ArrowCTA/> Back to Stories</Link>
          <div className="next">
            {!!next &&
              <>
                <Link to={`/stories/${next.slug.current}`} className="action">Next Article <ArrowCTA/></Link>
                <h3>{next.title}</h3>
              </>
            }
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query StoryQuery($id: String!) {
    sanityStory(_id: {eq: $id}) {
      title
      description
      image {
        alt
        ...Image
      }
      _rawBody
      slug {
        current
      }
    }
  }
`

export default StoryPage
